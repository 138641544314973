@import "./../../assets/scss/colors";
@import "./../../assets/scss/fonts";

.bin_change_password-container {
  padding: 0px 20px 20px 20px;
}
.profile-label {
  font: $label-larger;
  color: $stats-label-header;
}

.profile-value {
  font: $input-large;
  color: $black;
  height: 25px;
}

.profile-info {
  position: relative;
  background-color: $white;
  width: 100%;
  height: 100svh;
  // margin-top: 30px;
  // padding: 20px;
}

.edit-profile {
  position: relative;
  background-color: #f7f7f7;
  width: 100%;
  height: 100svh;
  // margin-top: 30px;
  // padding: 20px;
}

.profile-body {
  padding: 20px;
}

.edit-profile-body {
  background-color: #f7f7f7;
  padding: 0px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  height: calc(100svh - $header-height);
  justify-content: space-between;
}

.bin_profile-header {
  padding-left: 0px;
  height: $header-height;
  background-color: $header-color;
}

.profile-footer {
  position: absolute;
  bottom: 150px;
  // width: 100%;
  background-color: $header-color;
}

.edit-profile-footer {
  bottom: 160px;
  width: 100%;
  background-color: $header-color;
}

.logout-buffer {
  margin-bottom: 33px;
}

.resetPassMessage {
  text-align: center;
}

.resetPassMessageWrapper {
  background-color: white;
  margin: 27px 51px;
  box-shadow: 0 0 49px lightgrey;
  padding: 15px 10px 15px 10px;
  border-radius: 5px;
}

.bin_error-msg {
  color: #be1e10;
  font-size: 12px;
  font-weight: 600;
}
