@import './../../../assets/scss/colors';
@import './../../../assets/scss/fonts';

.bin_outside-field {
    margin-top: 20px;
    // margin-bottom: 20px;
}

.bin_input-label {
    margin-bottom: 10px;
    font: $label-large;
    color: $input-label;
}

.bin_input-field {
    position: relative;
    cursor: not-allowed;
    font-weight: bolder;
    font: $input;
    color: $black;
    width: 100%;
    height: 56px;

    &:focus {
        border: 1px solid $border-focus !important;
        cursor: auto;
    }
}


.bin_inside-field {
    cursor: not-allowed;
    border-radius: 6px;
    border: 1px solid $border;
    background-color: $white;
    font-weight: bolder;
    font: $input;
    color: $black;
    width: 100%;
    height: 56px;
    padding: 16px;

    &:focus {
        border: 1px solid $border-focus !important;
        cursor: auto;
    }
}

.bin_inside-field-error{
        border: 1px solid #C03F3D !important;  
}

.bin_eye-input {
    background-color: $white !important;
    border: none;
    position: absolute;
    right: 10px;
    top: 10px;

}

.bin_eye-input:focus {
    outline: none;
}