.bin_label_with_line-title {
    font-size: 16px;
    font-weight: 800;
}

.bin_label_with_line-border {
    height: 1px;
    margin-bottom: 6px;
    margin-left: 6px;
    background-color: #E2E2E2;
}