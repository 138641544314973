.bin_level-circle-view {
    position: relative;
    background-color: #fff;
    display: flex;
    border: 1px solid #E2E2E2;
    align-items: center;
    height: 56px !important;
    margin-bottom: 10px;
}

.bin_level-circle-view-active {
    background-color: #F2FFF7;
    border-color: #21B291;
}

.bin_level-circle {
    width: 19px;
    height: 19px;
    border-radius: 10px;
    margin: 0px 22px;
    z-index: 9999;
}

.bin_level-bigCircle {
    width: 33px;
    height: 33px;
    border-radius: 30px;
    margin: 5px 0;
    top: 6px;
    left: 15px;
    position: absolute;
    border: 3px solid;
}

.bin_level-title {
    font-size: 18px;
    font-weight: 800;
}