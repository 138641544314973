.bin_history_tile-title {
    font-weight: 700;
    font-size: 16px;
    color: #000;
}

.bin_history_tile-desc {
    font-weight: 400;
    font-size: 14px;
    color: #3C3B3B;
}

.bin_history_tile-container {
    border-bottom: 1px solid #E2E2E2;
    padding: 10px 0px;
}

.bin_history_status-text{
    color : #3C3B3B;
    font-size: 15px;
    margin-left: 5px;
}