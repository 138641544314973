@import './../../../../assets/scss/fonts';

.bin_detail-footer{
    position: fixed;
    background-color:  #fff !important;
    padding: 20px 5px;
    width: 100vw;
    max-width: 601px;
    bottom: 0;
    box-shadow: #00000029 0px 0px 10px;
}