@import './../../../assets/scss/colors';
@import './../../../assets/scss/fonts';

.bin_outside-text-area {
}

.bin_input-label {
    margin-bottom: 10px;
    font: $label-large;
    color: $input-label;
}

.bin_text-area {
    position: relative;
    cursor: not-allowed;
    // background-color: $white;
    font-weight: bolder;
    font: $input;
    color: $black;
    width: 100%;

    &:focus {
        border: 1px solid $border-focus !important;
       outline: none;
        cursor: auto;
    }
}


.bin_text-area-inside {
    cursor: not-allowed;
    border-radius: 6px;
    border: 1px solid $border;
    background-color: $white;
    font-weight: bolder;
    font: $input;
    color: $black;
    width: 100%;
    padding: 16px;

    &:focus {
        border: 1px solid $border-focus !important;
        outline: none;
        cursor: auto;
    }
}

.bin_eye-input {
    background-color: $white !important;
    border: none;
    position: absolute;
    right: 10px;
    top: 10px;

}

.bin_eye-input:focus {
    outline: none;
}