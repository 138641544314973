.bin_backdrop {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;

    &--active {
        opacity: 1;
        visibility: visible;
    }
}

.bin_modal {
    background-color: #F7F7F7;
    padding: 15px 20px 20px 20px;
    width: 100%;
    max-width: 601px;
    height: 100%;
    box-sizing: border-box;
    transition: all 0.3s ease;
    transform: translateY(100%);
    animation: slideUp 0.3s ease forwards;
}

.bin_modal_title {
    font-weight: 800;
    font-size: 18px;
}

@keyframes slideUp {
    to {
        transform: translateY(0);
    }
}