.bin_history-date-pill {
    color: #fff;
    background-color: #000;
    border-radius: 30px;
    padding: 3px 10px;
    font-size: 14px;
    margin: 15px 0px 10px 0px;
    width: max-content;
}

.bin_history-grid-container {
    background-color: #fff;
    padding: 5px 0px;
    height: calc(100svh - 120px);
    overflow-y: auto;
}

.bin_history-grid-container::-webkit-scrollbar {
    display: none;
}

.bin_history-date-title {
    font-size: 14px;
}

.bin_history-date-card {
    box-shadow: #00000019 0px 4px 4px;
    padding: 10px 20px;
}

.bin_accordion-header {
    cursor: pointer;
    padding: 10px 20px;
    height: 60px;
    background-color: #2B2D2F;
    color: #fff;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bin_accordion-body {
    padding: 0px 0px 0px 0px;
}

.bin_history-tile-container{
        padding: 0px 20px 0px 20px;
}

.bin_history-tile-container-today{
    background-color:#CCCCCC ;
        padding: 5px 20px 0px 20px;
}

.bin_accordion-header-text {
    font-size: 20px;

}