@import './../../../../../assets/scss/fonts';
@import './../../../../../assets/scss/colors';


.bin_pie-chart-container {
    background-color: #fff;
    border-top: 1px solid #E2E2E2;
    border-bottom: 1px solid #E2E2E2;
    margin-top: 10px;
    padding: 20px 30px;
}

.bin_legend-pie-text {
    color: #6A6A6A;
    font-size: 15px;
    font-weight: 500;
    margin: 5px 0px;
}

.bin_legend-pie-bold-text {

    font-weight: 700;
    color: #000;
    font-size: 15px;
}