@import './../../../assets/scss/colors';
@import './../../../assets/scss/fonts';

.bin_full_header {
    display: flex;
    align-items: start;
    justify-content: left;
    font: $header;
    color: $black;
    padding: 20px;
    width: 90%;
    height: 100svh;
    background-color: $header-color ;
    // background-color: $header-color ;
}

.bin_header {
    display: flex;
    align-items: center;
    justify-content: center;
    font: $header;
    color: $black;
    padding: 20px;
    width: 90%;
    height: 70px;
    background-color: $header-color ;
    // background-color: $header-color ;
}

.bin_header-content {
    font: $header;
    color: $black;
    width: 100%;
    background-color: #F7F7F7;
}

.bin_header-border {
    border-bottom: 1px solid #E2E2E2;
}


.bin_other_details {
    font-size: 2rem;
    font-weight: 700;
    color: $btn-font-color
}

.bin_other_details_line {
    // padding-left: 20px;
    line-height: 15px;
    width: calc(100% - 100px);
    border-bottom: 1px solid $border;
}