@import './../../../assets/scss/colors';
@import './../../../assets/scss/fonts';


.bin_btn-disabled {
    background-color: $btn-disabled-color;
    width: 100%;
    height: "66px";
    color: $btn-disabled;
    font: $button;
    padding: 20px;
    border-radius: 6px;
    border: none;
}

.bin_btn {
    background-color: $btn-bg-color;
    color: $white;
    width: 100%;
    height: "66px";
    padding: 20px;
    font: $button;
    border-radius: 6px;
    border: none;
}