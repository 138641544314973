.bin_dashboardActions {
    .bin_dashboardTitle {
        font-weight: 800;
        margin-bottom: 20px;
        font-size: 18px;
    }

 
}
   .bin_missing-alert-container {
       border-top: 1px solid #E2E2E2;
       margin-top: 20px;
       padding-top: 12px;
   }


   .bin_month-title{
    color  : #000;
    font-size: 15px;
   }

   .bin_missing-alert-icon {
     margin-right: 17px;
     margin-top: 4px;
   }