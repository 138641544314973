@import './../../../assets/scss/colors';
@import './../../../assets/scss/fonts';

.bin_page-card-label {
    border-radius: 19px;
    width: fit-content;
    padding: 10px 19px;
    background-color: #C3F3F5;
    font-size: 18px;
    font-style: italic;
    font-weight: 600;
}

.bin_page-card-body {
    height: calc(100svh - $header-height - $bottom-nav-height) !important;
    background-color: $header-color !important;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px;
}

.bin_page-card-body::-webkit-scrollbar {
    display: none;
}

.bin_page-card {
    background-color: $primary-color !important;
    box-shadow: #00000029 0px 0px 5px;
    padding: 0px;
    border-radius: 15px;
    margin-bottom: 20px;
    border: 1px solid #E2E2E2
}

.bin_detail_page-card {
    background-color: $primary-color !important;
    padding: 30px;
    padding-bottom: 220px;
    max-height: calc(100svh - 70px);
    overflow-y: auto;
}

.bin_detail_page-card::-webkit-scrollbar {
    display: none;
}

.bin_page-level-label {
    font-size: 2rem;
    padding: 0px 2rem;
    color: #6E6E6E;
    font-weight: 500;
}

.flex-shrink-1{
    flex-shrink: 1 !important;
}

.bin_page-level-value {
    display: flex;
    font-size: 2rem;
    padding: 5px 2rem;
    color: #000;
    font-weight: 700;
}

.bin_card-details {
    padding: 20px;
}

.bin_history_card-details{
    padding: 0px;
}

.bin_card-buttons {
    button {
        border-radius: 0;
    }

    button:last-child {
        border-radius: 0px 0px 15px 15px;
    }
}