@import './../../../../../assets/scss/fonts';
@import './../../../../../assets/scss/colors';


.bin_weekly-bars-container {
    background-color: #fff;
    border-top: 1px solid #E2E2E2;
    border-bottom: 1px solid #E2E2E2;
    margin-top: 10px;
    padding: 15px 30px 20px 30px;
}

.bin_week-title{
font-size: 13px;
color:  #000;
font-weight: 600;
margin-bottom: 5px;
}

.bin_weekly-day-text {
    min-width: 40px;
    color: #6A6A6A;
    font-size: 12px;
}

.bin_weekly-progress-bar{
z-index: 1 !important;
}
.bin_selected-weekly-progress-bar{
    background-color: #00000022;
    position: absolute;
    // top: 3px;
    left : -30px;
    height: 27px;
    width: 100vw;
    z-index: 0;
}

.bin_weekly-load-text {
    min-width: 20px;
    font-weight: 700;
    color: #000;
    font-size: 12px;
}