@import './../../assets/scss/colors';
@import './../../assets/scss/fonts';

.bin_login {
    background-color: $primary-bg-color;
    width: 100%;
    height: 100svh;
}

.bin_error-msg {
    color: #be1e10;
    font-size: 12px;
    font-weight: 600;

}


.bin_login-logo {
    height: 30%;
    display: flex;
    justify-content: center;
    align-items:center ;
}

.bin_logo {
    height: 72px;
}

.bin_login_checkbox {
    font: label-large;
    color: $input-label;
}

.bin_login_anchor {
    justify-content: flex-end;
    font-size: $anchor !important;
    color: anchor-color !important;
    text-decoration: underline;
}

.forgot-text {
    cursor:pointer;
    color:blue;
    text-decoration:underline;
}

.login-button {
  background-color: $white;
  color: $btn-bg-color;
  width: 100%;
  height: "66px";
  padding: 20px;
  font: $button;
  border-radius: 6px;
  border: solid;
}

.login-button-disabled {
  background-color: $btn-disabled-color;
  width: 100%;
  height: "66px";
  color: $btn-disabled;
  font: $button;
  padding: 20px;
  border-radius: 6px;
  border: none;
}