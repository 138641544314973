.bin_backdrop {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;

    &--active {
        opacity: 1;
        visibility: visible;
    }
}

.bin_bottomModal {
    background-color: white;
    border-radius: 30px 30px 0px 0px;
    padding: 15px 20px 20px 20px;
    width: 100%;
    max-width: 561px;
    z-index: 9999;
    box-sizing: border-box;
    transition: all 0.3s ease;
    transform: translateY(100%);
    animation: slideUp 0.3s ease forwards;
}

.bin_bottomModal-bar {
    height: 3px;
    background-color: #707070;
    width: 30px;
    margin: auto;

    margin-bottom: 25px;
}


@keyframes slideUp {
    to {
        transform: translateY(0);
    }
}