// _colors.scss

$primary-color: #FFFFFF;
$bg-color: #F7F7F7;
$header-color: #F7F7F7;
$secondary-color: #6c757d;
$success-color: #28a745;
$error-color: #dc3545;
$primary-bg-color: #F7F7F7;
$btn-disabled-color: #B1B1B1;
$btn-bg-color: #2B2D2F;
$btn-font-color: #6A6A6A;
$font-disabled-color: #6A6A6A;
$font-color: #FFFFFF;
$label-color: #7B7B7B;
$border-focus: #21B291;
$border: #E2E2E2;
$white: #FFFFFF;