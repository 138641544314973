@import 'colors';
@import 'fonts';


html {
    background-color: #F7F7F7;
    margin: 0;
    padding: 0;
}

body {
    background-color: #F7F7F7;
    margin: 0;
    padding: 0;
}

.flex-1 {
    flex: 1 !important;
}

.bin_page-content {
    height: calc(100svh - $header-height - $bottom-nav-height) !important;
    background-color: #F7F7F7 !important;
    border: 1px solid $border;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px;
}

.bin_page-content-l-header {
    height: calc(100svh - $header-l-height - $bottom-nav-height) !important;
    background-color: #F7F7F7 !important;
    border: 1px solid $border;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px;
}

.bin_page-content::-webkit-scrollbar {
    display: none;
}

.bin_page-content-l-header::-webkit-scrollbar {
    display: none;
}

.bin_open-page-content {
    height: 100svh;
    background-color: #F7F7F7 !important;
    max-width: 501px;
    padding: 20px;
}

.bin_page-header-content {
    display: flex;
    align-items: center;
    font: $header;
    color: $black;
    width: 90%;
    background-color: $primary-color;
}



.bin_dashboard-title {
    font-size: 20px;
    color: #6A6A6A;
    font-weight: 600;
    margin-bottom: 20px;
}

.bin_dashboard-bold-title {
    font-size: 20px;
    color: #000;
    font-weight: 700;
    margin-bottom: 20px;
}