.bin_navigation-icon {
    width: 36px;
    height: 36px;
    padding: 4px;
    // padding: 15px;
}

.bin_navigation-icon-active{
    background-color: #D6D6D6;
    border-radius: 40%;
}

.bin_bottom-navbar {
    width: 100vw;
    max-width: 601px;
    height: 60px;
}