.bin_history_tile-title {
    font-weight: 700;
    font-size: 16px;
    color: #000;
}

.bin_timeline_label{
    font-size: 14px;
    font-weight: 500;
    color: #3C3B3B;
}

.bin_dropdown {
    padding: 10px;
    box-shadow: #00000029 0px 0px 4px ;
    border: 1px solid #E2E2E2 ;
    border-radius: 5px;
    background-color: #F7F7F7;
}

.bin_history_tile-desc {
    font-weight: 400;
    font-size: 14px;
    color: #3C3B3B;
}

.bin_custom-dropdown-toggle {
    border: 0px !important;
    outline: 0px !important;
    box-shadow: none !important;
    background-color: #fff !important;
    color:  #0053D8 !important;
    font-weight: 700;
    text-decoration: underline !important;
    font-size: 16px !important;
}


.bin_history_detail-container {
    padding: 20px;
    height: calc(100svh - 70px);
    overflow-y: auto;
    background-color: #fff;
}

.bin_history_detail-container::-webkit-scrollbar {
    display: none;
}



.bin_key_value-title {
    color: #6A6A6A;
    font-size: 16px;
    width: 100px;
    margin-right: 10px;
    margin-left: 15px;
    margin-bottom: 8px;
    font-weight: 600;
}

.bin_key_value-answer {
    font-size: 16px;
    width: calc(100% - 130px);
    color: #000000;
    margin-bottom: 8px;
    font-weight: 600;
}