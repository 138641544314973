

@import './../../../../assets/scss/fonts';
@import './../../../../assets/scss/colors';

.bin_btn-toggle {
    background-color: #E2E2E2;
    height: 35px;
    min-width: 120px;
    color: #4B4A4A;
    padding: 8px;
    border-radius: 4px;
    outline: none !important;
    border: 1px solid #B9B9B9;
}

.bin_btn-toggle-active {
    background-color: #F4FFF7;
    min-width: 120px;
    color: #000;
    height: 35px;
    padding: 8px;
    border-radius: 4px;
    outline: none !important;
    border: 1px solid #057A5F;
}


