.bin_month-picker {
    // Apply your custom styles as needed
    position: relative; // for positioning the date text

    .bin_month-input {
            max-width: 571px;
            width: calc(100vw - 25px) !important;
        background: #fff !important;
        border: 1px solid #ddd !important;
        border-radius: 4px !important;
        padding: 8px !important;
        height: 40px !important;

        // Style for the selected date (if today)
        &.bin_today {
            border-color: green; // Highlight with green color
        }

        // Style for the highlighted date
        &:focus {
            outline: none;
            // box-shadow: 0 0 0 2px rgba(0, 128, 0, 0.5) !important; // Adjust the color and size as needed
        }

    }
}