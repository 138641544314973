// font colors
$input-label: #7B7B7B;
$stats-label-header: #6A6A6A;
$label-header: #3C3B3B;
$black: #000000;
$white: #FFFFFF;
$btn-disabled: #6A6A6A;
$anchor-color: #0053D8;


// font weights
$font-300: 300;
$font-400: 400;
$font-500: 500;
$font-600: 600;
$font-700: 700;
$font-800: 800;

// font styles 
$title: 600 16px/24px Open Sans, sans-serif;
$header: 700 20px/20px Open Sans, sans-serif;
$description: 500 Italic 16px/24px Open Sans, sans-serif;
$label-small: 400 16px/24px Open Sans, sans-serif;
$label: 600 16px/24px Open Sans, sans-serif;
$label-large: 500 14px/14px Open Sans, sans-serif;
$label-larger: 600 16px/16px Open Sans, sans-serif;
$anchor: 700 14px/14px Open Sans, sans-serif;
$paragraph: 400 16px/24px Open Sans, sans-serif;
$button: 600 18px Open Sans, sans-serif;
$input: 600 16px/16px Open Sans, sans-serif;
$input-large: 700 16px/16px Open Sans, sans-serif;

$header-height : 67px;
$header-l-height : 115px;
$bottom-nav-height : 70px;

